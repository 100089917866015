import "../styles/post_item.scss";

export default function PostItem (props) {

    return (
        <div className="post">
            <div className="blob">
                <img src={props.image} alt="" />
            </div>
            <div className="detail">
                <h3>{props.title}</h3>
                <p>30th August 2024</p>
            </div>
        </div>
    );
}