import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import WebMaintenance from './pages/web_maintenance';
import Careers from './pages/careers';
import ContactUs from './pages/contactus';
import Tenders from './pages/tenders';
import Services from './pages/services';
import AboutUs from './pages/aboutus';
import Board from './pages/board';
import Management from './pages/management';
import Statement from './pages/statement';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path = "/" element = {<HomePage />}/>
        <Route exact path = "/services" element = {<Services />}/>
        <Route exact path = "/tenders" element = {<Tenders />}/>
        <Route exact path = "/careers" element = {<Careers />}/>
        <Route exact path = "/contactus" element = {<ContactUs />}/>

        <Route exact path = "/aboutus" element = {<AboutUs />}/>
        <Route exact path = "/board" element = {<Board />}/>
        <Route exact path = "/management" element = {<Management />}/>
        <Route exact path = "/management" element = {<Services />}/>
        <Route exact path = "/statements/chair" element = {<Statement />}/>
        <Route exact path = "/statements/md" element = {<Statement />}/>
        <Route exact path = "/newsandevents" element = {<Careers />}/>
        <Route exact path = "/reports" element = {<ContactUs />}/>
        <Route exact path = "/downloads" element = {<Careers />}/>
        <Route exact path = "/gallery" element = {<ContactUs />}/>
        <Route exact path = "/blog" element = {<ContactUs />}/>
        <Route exact path='/*' element = {<WebMaintenance />} />
      </Routes>
    </Router>
  );
}

export default App;
