import { useEffect, useState } from "react";
import "../styles/careers.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg"

export default function Tenders (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="careers" id="careers">
        <div className="centerheading">
          <h2 className="decorated-heading">Tenders</h2>
        </div>
        <hr />
        <div className="centertext">
          <div className="centerheading">
            <h3 className="heading">No Current Tenders Available</h3>
          </div>
          <p>At the moment, there are no active tenders. Please check back regularly for updates.</p>
          <p>Thank you for your interest in partnering with us.</p>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}