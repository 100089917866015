import { useEffect, useState } from "react";
import "../styles/aboutus.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";

export default function AboutUs (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>About Githunguri Water and Sanitation Company</p>
        </div>
      </div>
      <div className="aboutus" id="aboutus">
        <div className="heading">
          <h2 className="decorated-heading">Who we are</h2>
        </div>
        <div className="aboutInfo">
            <p>Githunguri Water and Sanitation Company Limited (GIWASCO) is a Water Service Provider operating in Kiambu County 
              in line with Water Act 2002 and was incorporated on the 24th April, 2007 under the company’s act cap 486 and 
              appointed agent of Athi Water Services Board (AWSB) under an SPA to provide efficient, effective good quality and 
              affordable water and sanitation services within Githunguri and lower Lari districts covering 244 km<sup>2</sup>. The company 
              started operations on October 1st 2007.</p>
              <p>In October 2013 the Company changed its Memos and Articles of Association in line with Constitution of Kenya 
                2010 to be an agent of County Government of Kiambu as water services provision was a devolved function.</p>

        </div>
        <hr />
        <div className="heading">
          <h2 className="decorated-heading">Vision</h2>
        </div>
        <div className="aboutInfo">
            {/* <p>To be the market leader in the provision of reliable, quality, sufficient and affordable water services in the region.</p> */}
            <p>To be the number one water service provider in Kiambu County.</p>
        </div>
        <hr />
        <div className="heading">
          <h2 className="decorated-heading">Mision</h2>
        </div>
        <div className="aboutInfo">
            {/* <p>To ensure provision of efficient, effective, good quality water services in our area of jurisdiction and beyond.</p> */}
            <p>To provide effective, efficient, safe and Sustainable water and sanitation services to the satisfaction of our internal and external customers</p>
        </div>
        <hr />
        <div className="heading">
          <h2 className="decorated-heading">Core Values</h2>
        </div>
        <div className="aboutInfo">
            <ul>
              <li>Customer focus</li>
              <li>Integrity</li>
              <li>Teamwork</li>
              <li>Creativity and innovation</li>
              <li>Sustainability</li>
              <li>Professionalism</li>
              <li>Transparency & Accountability</li>
              <li>Time</li>
              {/* <li>Fairness</li> */}
              {/* <li>Result Oriented</li>
              <li>Good corporate governance practices</li> */}
            </ul>
        </div>
        <hr />
        <div className="heading">
          <h2 className="decorated-heading">Our Commitment</h2>
        </div>
        <div className="aboutInfo">
            <ul>
              <li>We commit to provide a level of customer care, which will at the very least, meet our customers’ expectations.</li>
              <li>To act in the best interest of our customers and continually improve our standards of quality in every aspect of our services.</li>
              <li>All Company staff pledge to our current and future customers the highest quality service.</li>
            </ul>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}