import { useEffect, useState } from "react";
import "../styles/board.scss";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import personicon from "../assets/imgs/personicon.jpg"

export default function Board (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>Our Board of Management</p>
        </div>
      </div>
      <div className="board">
        <div className="persons">
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>Godfrey Mwaura Kigochi</h3>
                <p>Chairman</p>
                <p>Qualifications</p>
              </div>
          </div>
        </div>
        <div className="persons">
          <div className="person">
            <div className="photo">
              <img src={personicon} alt="" />
            </div>
            <div className="details">
              <h3>Bishop Solomon Wairiri</h3>
              <p>Director</p>
              {/* <p>Qualifications</p> */}
            </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>James Gichanga</h3>
                <p>Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>William Kimani</h3>
                <p>Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>G.N Kang'ara</h3>
                <p>Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>Grace Nduta Gitau</h3>
                <p>Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>Jennifer Kanini Musyoka</h3>
                <p>Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
        </div>
        <div className="persons">
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>Francis Kahuha</h3>
                <p>Managing Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}