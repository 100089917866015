import { useEffect, useState } from "react";
import "../styles/home.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg"
import QuickServices from "../components/quickservices";

export default function HomePage (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  
  return (
  <div>
    <Banner />
    <Header toggleMenu={toggleMenu} 
      setToggleMenu={setToggleMenu}
    />
    {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
      setToggleMenu={setToggleMenu}
    />}
    <div className="home" id="home">
      <div className="landingscreen">
          {toggleQuickServices ? <QuickServices /> : 
          <div className="content">
              <div className="techimage">
                  <p>.</p>
                  <p>.</p>
                  <h3>Welcome to Githunguri Water and Sanitation Company Ltd.</h3>
                  <hr />
                  <Button title="How may we Help You?" action={ () => setToggleQuickServices(true)} color="white"/>
              </div>
          </div>}
      </div>
      <div className="main1">
        <div className="main1bg">
          <div className="section1">
                <div className="intro">
                    <h2 className="color-animation">Thank you for visiting Githunguri Water! </h2>
                    <p>
                    At Githunguri Water and Sanitation Company, we are committed to providing clean, safe, and reliable water 
                    services to the residents of Githunguri and its surrounding areas. Our dedication to excellence ensures that 
                    we meet the water needs of our community with efficiency and sustainability. From advanced water treatment 
                    processes to effective distribution systems, we strive to deliver services that enhance the quality of life for 
                    our customers. </p>
                    <p>We understand the critical role that water plays in our daily lives, and we take pride in being 
                    a trusted partner in the community’s development. At Githunguri Water and Sanitation Company, every drop counts, 
                    and we are dedicated to managing this precious resource responsibly, ensuring that Githunguri thrives now and in the future.</p>
                </div>
          </div>
          <div className="section2" id="services">
              <div className="centerheading">
                <h2 className="decorated-heading">Our Services</h2>
              </div>
              <div className="whatwedo">
                <div className="service">
                  <FontAwesomeIcon className="index" icon={faFaucetDrip} />
                  <h3>New Water Connection</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faSync} />
                  <h3>Water Reconnection</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faTools} />
                  <h3>Operation & Maintenance</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faHeadset} />
                  <h3>Customer Service</h3>
                </div>
              </div>

              <div className="wasrebkpis">
                <div className="items">
                  <div className="kpi">
                    <h3>Supply Hours</h3>
                    <p>24hrs</p>
                  </div>
                  <div className="kpi">
                    <h3>Non Revenue Water</h3>
                    <p>67%</p>
                  </div>
                  <div className="kpi">
                    <h3>Water Coverage</h3>
                    <p>25%</p>
                  </div>
                  <div className="kpi">
                    <h3>Drinking Water Quality</h3>
                    <p>100%</p>
                  </div>
                  <div className="kpi">
                    <h3>Meter Coverage</h3>
                    <p>60%</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="main2" id="why">
        <div className="main2bg">
          <div className="whyus">
              <div className="centerheading">
                <h2  className="decorated-heading">Our Core Values</h2>
              </div>
              <p>At Githunguri Water and Sanitation Company, we are committed to delivering reliable and innovative water solutions that enhance the quality of life for our community. Discover why partnering with us ensures access to top-notch water services:</p>
              <div className="container">
                <div className="reason data">
                  <h3>Expertise</h3>
                  {/* <p>Our team consists of highly skilled professionals with expertise in various technologies and domains. We have years of experience delivering successful projects for clients across industries.</p> */}
                </div>
                <div className="reason nodata"></div>

                <div className="reason data">
                  <h3>Innovation</h3>
                  {/* <p>We stay ahead of the curve by embracing the latest trends and innovations in technology. From AI and machine learning to blockchain and IoT, we leverage cutting-edge tools and methodologies to drive innovation and create value for our clients.</p> */}
                </div>

                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Quality</h3>
                  {/* <p>Quality and reliability are at the core of everything we do. We adhere to industry best practices and rigorous quality standards to ensure that our solutions are robust, scalable, and reliable. You can trust us to deliver results that exceed your expectations.</p> */}
                </div>
                <div className="reason nodata"></div>

                {/* <div className="reason data">
                  <h3>Customization</h3>
                  <p>We understand that every business is unique. That's why we offer customized solutions tailored to your specific needs and requirements. Whether you're a small startup or a large enterprise, we have the expertise to deliver solutions that fit your goals.</p>
                </div> */}
                <div className="reason nodata"></div>

                <div className="reason data extra-item">
                  <h3>Collaboration</h3>
                  {/* <p>We believe in building strong relationships with our clients based on trust, transparency, and collaboration. We work closely with you throughout the project lifecycle, from initial consultation to final delivery, ensuring needs are met and expectations exceeded.</p> */}
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main3" id="news">
        <div className="main3bg">
            <div className="centerheading">
              <h2  className="decorated-heading">News and Events</h2>
            </div>
            <div className="news">
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
              <PostItem image={allstaffmeeting} title="All Staff Meeting to be held at Gatamaiyu Tea Works" date="Fri 30th August 2024" />
            </div>
            <br />
            <div className="allnews">
              <Button title="All News >>" action={setToggleQuickServices} color="blue"/>
            </div>
        </div>
      </div>
      <div className="main4" id="partners">
        <div className="main3bg">
            <div className="centerheading">
              <h2  className="decorated-heading">Our Partners</h2>
            </div>
            <div className="partners">
              
            </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}