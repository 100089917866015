import "../styles/quickservices.scss";
import team from "../assets/imgs/GIWASCO-team.jpg";
import team2 from "../assets/imgs/GIWASCO-team.jpg";
import team3 from "../assets/imgs/GIWASCO-team.jpg";
import team4 from "../assets/imgs/GIWASCO-team.jpg";
import newconnection from "../assets/imgs/servicesicons/faucet.png";
import paywater from "../assets/imgs/servicesicons/payment-method.png";
import lodgecomplain from "../assets/imgs/servicesicons/rating.png";
import kiamerufloc from "../assets/imgs/companypictures/kiamerufloc.jpg";
import kiamerufloc3 from "../assets/imgs/companypictures/kiamerufloc3.jpg";
import managementwithdanida from "../assets/imgs/companypictures/managementwithdanida.jpg";
import pipeinstallation from "../assets/imgs/companypictures/pipeinstallation.jpg";
import corruption from "../assets/imgs/servicesicons/bribe.png";
import careers from "../assets/imgs/servicesicons/career.png";
import reports from "../assets/imgs/servicesicons/report.png";
import publications from "../assets/imgs/servicesicons/seo-report.png";
import newsevents from "../assets/imgs/servicesicons/calendar.png"
import contacts from "../assets/imgs/servicesicons/complain.png";
import Carousel from "./carousel";

import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import SingleServiceLink from "./singleservicelink";

export default function QuickServices (props) {

    return (
        <div className="quickservices">
            {/* <div className="carousel">
                <img src={team} alt="" />
            </div> */}
            <Carousel images = {[team, kiamerufloc, managementwithdanida, kiamerufloc3, pipeinstallation]}/>
            <div className="quickbuttons">
                <SingleServiceLink icon= {newconnection} text="New Water Connection" page="services" />
                <SingleServiceLink icon= {paywater} text="Pay for services" page="services" />
                <SingleServiceLink icon= {lodgecomplain} text="Lodge a complain" page="contactus" />

                <SingleServiceLink icon= {corruption} text="Report corruption" page="reportcoruption" />
                {/* <SingleServiceLink icon= {faFaucetDrip} text="Tenders" /> */}
                <SingleServiceLink icon= {careers} text="Careers" page="careers"/>

                <SingleServiceLink icon= {reports} text="Reports" page="reports" />
                <SingleServiceLink icon= {publications} text="Publications & Downloads" page="publications"/>
                <SingleServiceLink icon= {newsevents} text="News & Events" page="news"/>
                
                <SingleServiceLink icon= {contacts} text="Contact Us" page="contactus" />
            </div>
        </div>         
    );
}