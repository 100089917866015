import { useEffect, useState } from "react";
import "../styles/careers.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg"

export default function Careers (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="careers" id="careers">
        <div className="centerheading">
          <h2 className="decorated-heading">Work with Us</h2>
        </div>
        <hr />
        <div className="centertext">
          <div className="centerheading">
            <h3 className="heading">Job Openings</h3>
          </div>
          <p>Thank you for your interest in joining our team. Although we don't have any open positions at the moment, 
            we appreciate your interest in being a part of our journey. <br/>
            Please check back later for updates on new opportunities.</p>
        </div>
        <hr />
        <div className="centertext">
          <div className="centerheading">
            <h3 className="heading">Attachments and Interships</h3>
          </div>
          <p>Thank you for your interest in gaining practical experience with Githunguri Water. While we don't have any available 
            positions for student attachments or internships at the moment, we're always eager to be instrumental in student mentorship and developement
            students. <br /> 
            Please send your resume and a brief introduction to attachments@githunguriwater.co.ke. We’ll reach out if a suitable opportunity arises.</p>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}