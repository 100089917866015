import { useEffect, useState } from "react";
import "../styles/services.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg";
import newconnection from "../assets/imgs/servicesicons/faucet.png";
import newconnapplicationform from "../assets/docs/newconnectionform.pdf";

export default function Services (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  // const downloadFile = () => {
  //   // Create a temporary link element
  //   const link = document.createElement('a');
  //   link.href = "/newconnectionform.pdf"; // Path to the file in the public folder
  //   link.download = 'application-form.pdf'; // Filename for the downloaded file
  //   document.body.appendChild(link); // Append link to the body
  //   link.click(); // Programmatically click the link to trigger the download
  //   document.body.removeChild(link); // Remove the link from the document
  // };

  const openFile = () => {
    const fileUrl = newconnapplicationform; // Path to the file in the public folder
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  };
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="services" id="services">
        <div className="centerheading">
          <h2 className="decorated-heading">Services</h2>
        </div>
        <hr />
        <div className="centerheading">
          <h2 className="">New Water Connection Requirements</h2>
        </div>
        <div className="serviceitems">
          <div className="service">
              <h4>Requirements for Individuals (Domestic Use)</h4>
              <ul>
                <li>A copy of your national identification card (ID) </li>
                <li>A copy of your PIN certificate (KRA)</li>
                <li>A copy of tittle deed</li>
                <li>One passport photo</li>
                <li>Dully filled application form</li>
              </ul>
          </div>
          <div className="service">
              <h4>Requirements for Companies and Institutions</h4>
              <ul>
                <li>A copy of certificate of incorporation</li>
                <li>A copy of PIN certificate</li>
                <li>A copy of tittle deed /Land ownership</li>
                <li>Dully filled application form</li>
                <li>Institution Stamp</li>
              </ul>
          </div>
          <div className="service">
              <h4>New connection procedure</h4>
              <ul>
                <li>Fill the new connection application form</li>
                <li>Our team conducts a Site Assessment survey</li>
                <li>Pay water deposit amount and connection fee </li>
                <li>Get connected. Hurry!!!</li>
              </ul>
              <div className="imgcon">
                <img src={newconnection} alt="" />
              </div>
          </div>
          <div className="service">
              <h4>New Connection Payments</h4>
              <h5>New connection payments for Individuals (Domestic Use)</h5>
              <ul>
                <h5>Water deposit</h5>
                <li> - KCB Bank, Account number: 1105027597</li> 
                <li>Amount: Ksh. 1,500</li>
                <h5>Connection fee</h5> 
                <li> - KCB Bank, Account number: 1105027023</li>
                <li>Amount: Ksh. 4,500 /=</li>
              </ul>
              <h5>New connection payments for Companies & Institutions</h5>
              <ul>
                <li>Water deposit - KCB Bank, Account number: 1105027597</li>
                <li>Connection fee - KCB Bank, Account number: 1105027023</li>
              </ul>
          </div>
          <div className="service">
              <h4>Water bill payments</h4>
              <ul>
                <li>EQUITY BANK account -0930293233664</li>
                or
                <h5>M-pesa paybill</h5>
                <li><li>Business number:- 905900,</li> <li>Account no: Customer Connection Number</li></li>
              </ul>
          </div>
          <div className="service">
              <div className="placeholder">
                
              </div>
          </div>
        </div>
        <br />
        <div className="centerheading">
          <Button title="Download Application Form" action={openFile} color="blue"/>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}