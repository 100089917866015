import { useEffect, useState } from "react";
import "../styles/contactus.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";

export default function ContactUs (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  const mainoffice = {lat: -1.0573252051583522, lng: 36.77738982259471};
  const migutaoffice = {lat: -1.0709323881305357, lng: 36.82480339783219};
  const kagweoffice = {lat: -1.0038785297044281, lng: 36.741532873373984};

  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="contactus" id="contactus">
        <div className="centerheading">
          <h2 className="decorated-heading">Contact Us</h2>
        </div>
        <hr />
        <div className="contactInfo">
            <div className="centerheading">
                <h2>Our Offices</h2>
            </div>
            <div className="offices">
                <div className="office">
                  <div className="officedetails">
                    <h3>Head Office</h3>
                    <p>Adjacent Githunguri Police Station, </p>
                    <p>Githunguri town, Kiambu, Kenya.</p>
                    <p>Phone: +254700484034</p>
                    <p>Email: info@githunguriwater.com</p>
                  </div>
                  <LocationDiv office={mainoffice} />
                </div>
                <div className="office">
                  <div className="officedetails">
                    <h3>Kagwe Office,</h3>
                    <p>Kagwe town, </p>
                    <p>Kiambu, Kenya.</p>
                    <p>Phone: +254731554433</p>
                    <p>Email: info@githunguriwater.com</p>
                  </div>
                  <LocationDiv office={kagweoffice} />
                </div>
                <div className="office">
                  <div className="officedetails">
                    <h3>Miguta Office</h3>
                    <p>Miguta,</p>
                    <p>Kiambu, Kenya.</p>
                    <p>Phone: +254731554433</p>
                    <p>Email: info@githunguriwater.com</p>
                  </div>
                  <LocationDiv office={migutaoffice} />
                </div>
            </div>
        </div>
        <hr/>
        <form className="form">
            <div className="centerheading">
                <h2>Get in Touch</h2>
            </div>
            <div className="formGroup">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" placeholder="Your Name" />
            </div>
            <div className="formGroup">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Your Email" />
            </div>
            <div className="formGroup">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" placeholder="Your Message" rows="5"></textarea>
            </div>
            <div className="submit">
                <Button title="Submit" action={""} color="white"/>
            </div>
        </form>
        <hr />
      </div>
      <Footer />
    </div>
  );
}

function LocationDiv (props) {

  const handleMapClick = (lat, lng) => {
    
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div>
      <div className="map-container">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCKbk3jv0yEhk6QKnMwYj_c4o8A4MR01UM&q=${props.office.lat},${props.office.lng}`}
          width="600"
          height="400"
          allowFullScreen
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>
      <button className="view-map-button" onClick = { () => handleMapClick(props.office.lat, props.office.lng)}>
        View on Google Maps
      </button>
    </div>
  )
}