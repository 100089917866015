import { useEffect, useState } from "react";
import "../styles/management.scss";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import personicon from "../assets/imgs/personicon.jpg";
import md from "../assets/imgs/management/MD.png";
import tm from "../assets/imgs/management/tm.jpg";
import accountant from "../assets/imgs/management/accountant.jpg";
import senioreng from "../assets/imgs/management/senioreng.jpg";
import billing from "../assets/imgs/management/billing.jpg";
import procurement from "../assets/imgs/management/procurement.jpg";
import hr from "../assets/imgs/management/hr.jpg";
import internalaudit from "../assets/imgs/management/internalaudit.jpg";
import komothai from "../assets/imgs/management/komothaische.jpg";
import githunguri from "../assets/imgs/management/githungurische.jpg";
import gis from "../assets/imgs/management/gis.jpg";
import procurementassist from "../assets/imgs/management/procurementassist.jpg";

export default function Management (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>Our Management Team</p>
        </div>
      </div>
      <div className="management">
        <div className="persons">
          <div className="person">
              <div className="photo">
                <img src={md} alt="" />
              </div>
              <div className="details">
                <h3>Francis Kahuha</h3>
                <p>Managing Director</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
        </div>
        <div className="persons">
          <div className="person">
            <div className="photo">
              <img src={personicon} alt="" />
            </div>
            <div className="details">
              <h3>Daniel Muiruri</h3>
              <p>Finance Manager</p>
              {/* <p>Qualifications</p> */}
            </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={tm} alt="" />
              </div>
              <div className="details">
                <h3>Eng. Willy Kamau</h3>
                <p>Technical Manager</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={personicon} alt="" />
              </div>
              <div className="details">
                <h3>Vacant</h3>
                <p>Commercial Manager</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={accountant} alt="" />
              </div>
              <div className="details">
                <h3>CPA. Alex Waruhiu</h3>
                <p>Chief Accountant</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={senioreng} alt="" />
              </div>
              <div className="details">
                <h3>Eng. Christopher Gatheca</h3>
                <p>Senior Engineer</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={billing} alt="" />
              </div>
              <div className="details">
                <h3>Peris Wambui Gichuhi</h3>
                <p>Billing Officer</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={procurement} alt="" />
              </div>
              <div className="details">
                <h3>Cecilia Wanjiru Gakuha</h3>
                <p>Head of SuppLy Chain</p>
                <p>BPSM MKISM</p>
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={hr} alt="" />
              </div>
              <div className="details">
                <h3>Anne Nyambura Maina</h3>
                <p>Human Resource and Admin Officer</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={internalaudit} alt="" />
              </div>
              <div className="details">
                <h3>CPA. Anne Wambii Gachiku</h3>
                <p>Manager Audit, Risk and Compliance</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={komothai} alt="" />
              </div>
              <div className="details">
                <h3>Joseph King'ori</h3>
                <p>Komothai Scheme Manager</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={githunguri} alt="" />
              </div>
              <div className="details">
                <h3>Gabriel Kariuki</h3>
                <p>Githunguri Scheme Manager</p>
                {/* <p>Qualifications</p> */}
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={gis} alt="" />
              </div>
              <div className="details">
                <h3>James Mwenda Kaburu</h3>
                <p>GIS Officer</p>
                <p>B.Tech Geo-information Technology</p>
              </div>
          </div>
          <div className="person">
              <div className="photo">
                <img src={procurementassist} alt="" />
              </div>
              <div className="details">
                <h3>Rachael</h3>
                <p>Procurement Assistant</p>
                {/* <p></p> */}
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}