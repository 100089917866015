import '../styles/web_maintenance.scss';

export default function WebMaintenance (props) {
    return (
        <div className="maintenance-message">
            <div className='mg'>
            <h1>Website Under Maintenance</h1>
            <p>We are currently performing scheduled maintenance. Please check back soon.</p>
            </div>
        </div>
    )
}