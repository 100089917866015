import { faEnvelope, faLocationPin, faMessage, faPhone, faShare, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function QuickLinks (props) {
    return (
        <div className="website">
                    <div className="pages">
                        <p className="heads">Pages</p>
                        <br />
                        <a href="/" >Home</a>
                        <a href="/about">About</a>
                        <a href="/blog">Services</a>
                        <a href="/" >Media</a>
                        <a href="/about">Tenders</a>
                        <a href="/blog">Careers</a>
                        <a href="/" >Contact Us</a>
                    </div> 
                    {/* <div className="sections">
                        <p className="heads">Sections</p>
                        <br />
                        <a href="#home" >Welcome</a>
                        <a href="#services">Our Services</a>
                        <a href="#why">Why Us?</a>
                    </div> */}
                    <div className="reachus">
                        <p className="heads">Reach Us</p>
                        <br />
                        <div className="contsloc">
                            <FontAwesomeIcon icon={faUserCheck} />
                            <a href="https://githunguriwater.co.ke/customerservice">Customer Service</a>
                        </div>
                        <br />
                        <div className="contsloc">
                            <FontAwesomeIcon icon={faPhone} />
                            <p>+254700484034 | +254731554433</p>
                        </div>
                        <br />
                        <div className="contsloc">
                            <FontAwesomeIcon icon={faShare} />
                            <p>PO. Box 823-00216, Githunguri Kenya</p>
                        </div>
                        <br />
                        <div className="contsloc">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p>info@githunguriwater.co.ke</p>
                        </div>
                        <br />
                        <div className="contsloc">
                            <FontAwesomeIcon icon={faLocationPin} />
                            <p>Within Githunguri Police Station, Opposite Family Bank, Githunguri Town.</p>
                        </div>
                    </div>
                </div>
    )
}