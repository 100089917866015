import "../styles/menu_tab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun, faX} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/imgs/hexlogosinpalabrascrop.png"
import { useState } from "react";
import QuickLinks from "./quick_links";

export default function MenuTab (props) {

    return (
        <div className= "menutab">
            <div className="navigation">
                <div className="catchphrase">
                    <p>DISCOVER your world way differently with Hexhus. ..</p>
                    <p>Achieve more with us!</p>
                </div>
                <QuickLinks />
                <div className="logo">
                    <img src={logo} alt="" />
                    <h1>Hexhus</h1>
                </div>
                <div className="close">
                    <FontAwesomeIcon icon={faX} onClick={() => props.setToggleMenu(!props.toggleMenu)}/>
                </div>
            </div>
        </div>
    );
}