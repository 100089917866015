import React from 'react';
import { useEffect, useState } from "react";
import '../styles/statement.scss';
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import MD from "../assets/imgs/management/MD.jpg"
import personicon from "../assets/imgs/personicon.jpg";

export default function Statement (props) {

    const [toggleMenu, setToggleMenu] = useState(false);

    console.log(window.location.pathname.split("/")[2]);

    const target = window.location.pathname.split("/")[2];

    return (
        <div>
            <Banner />
            <Header toggleMenu={toggleMenu} 
                setToggleMenu={setToggleMenu}
            />
            {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
            setToggleMenu={setToggleMenu}
            />}
            { target === "md" ? 
            <div>
                <div className="image">
                    <div>
                        <p>Managing Director's Statement</p>
                    </div>
                </div>
                <div className="statement-container">
                <div className="statement-image-container">
                    <img
                    src={MD} 
                    alt="MD"
                    className="statement-image"
                    />
                </div>
                <div className="statement-text">
                    <h2>Managing Director's Statement</h2>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                    dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                    metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                    pharetra urna eros nec velit. Integer convallis magna nec turpis
                    varius, at dignissim neque viverra. Proin commodo malesuada diam,
                    vitae consequat risus eleifend in.
                    </p>
                    <p>
                    Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                    quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                    scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                    justo ultricies metus, eget facilisis nulla nunc eget neque.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                    dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                    metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                    pharetra urna eros nec velit. Integer convallis magna nec turpis
                    varius, at dignissim neque viverra. Proin commodo malesuada diam,
                    vitae consequat risus eleifend in.
                    </p>
                    <p>
                    Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                    quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                    scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                    justo ultricies metus, eget facilisis nulla nunc eget neque.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                    dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                    metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                    pharetra urna eros nec velit. Integer convallis magna nec turpis
                    varius, at dignissim neque viverra. Proin commodo malesuada diam,
                    vitae consequat risus eleifend in.
                    </p>
                    <p>
                    Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                    quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                    scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                    justo ultricies metus, eget facilisis nulla nunc eget neque.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                    dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                    metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                    pharetra urna eros nec velit. Integer convallis magna nec turpis
                    varius, at dignissim neque viverra. Proin commodo malesuada diam,
                    vitae consequat risus eleifend in.
                    </p>
                    <p>
                    Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                    quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                    scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                    justo ultricies metus, eget facilisis nulla nunc eget neque.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                    dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                    metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                    pharetra urna eros nec velit. Integer convallis magna nec turpis
                    varius, at dignissim neque viverra. Proin commodo malesuada diam,
                    vitae consequat risus eleifend in.
                    </p>
                    <p>
                    Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                    quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                    scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                    justo ultricies metus, eget facilisis nulla nunc eget neque.
                    </p>
                </div>
                </div>
            </div> 
            : 
            <div>
                <div className="image">
                    <div>
                        <p>Chairmans's Statement</p>
                    </div>
                </div>
                <div className="statement-container">
                    <div className="statement-image-container">
                        <img
                        src={personicon} 
                        alt="MD"
                        className="statement-image"
                        />
                    </div>
                    <div className="statement-text">
                        <h2>Managing Director's Statement</h2>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                        dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                        metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                        pharetra urna eros nec velit. Integer convallis magna nec turpis
                        varius, at dignissim neque viverra. Proin commodo malesuada diam,
                        vitae consequat risus eleifend in.
                        </p>
                        <p>
                        Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                        quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                        scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                        justo ultricies metus, eget facilisis nulla nunc eget neque.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                        dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                        metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                        pharetra urna eros nec velit. Integer convallis magna nec turpis
                        varius, at dignissim neque viverra. Proin commodo malesuada diam,
                        vitae consequat risus eleifend in.
                        </p>
                        <p>
                        Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                        quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                        scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                        justo ultricies metus, eget facilisis nulla nunc eget neque.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                        dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                        metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                        pharetra urna eros nec velit. Integer convallis magna nec turpis
                        varius, at dignissim neque viverra. Proin commodo malesuada diam,
                        vitae consequat risus eleifend in.
                        </p>
                        <p>
                        Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                        quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                        scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                        justo ultricies metus, eget facilisis nulla nunc eget neque.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                        dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                        metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                        pharetra urna eros nec velit. Integer convallis magna nec turpis
                        varius, at dignissim neque viverra. Proin commodo malesuada diam,
                        vitae consequat risus eleifend in.
                        </p>
                        <p>
                        Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                        quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                        scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                        justo ultricies metus, eget facilisis nulla nunc eget neque.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula
                        dui nec ligula faucibus, ac feugiat velit viverra. Nullam tristique,
                        metus ut auctor posuere, lorem urna fringilla sapien, sit amet
                        pharetra urna eros nec velit. Integer convallis magna nec turpis
                        varius, at dignissim neque viverra. Proin commodo malesuada diam,
                        vitae consequat risus eleifend in.
                        </p>
                        <p>
                        Sed tempus bibendum quam, at pharetra ligula condimentum sed. Nulla
                        quis lorem augue. Fusce mollis lacinia orci, vel tincidunt nisi
                        scelerisque ut. Quisque scelerisque, leo at fringilla convallis, neque
                        justo ultricies metus, eget facilisis nulla nunc eget neque.
                        </p>
                    </div>
                </div>
            </div>}
            <Footer />
        </div>
    );
};
